<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    width="512"
    height="512"
  >
    <path
      d="M19.5,2H7.5c-2.49,0-4.5,2.01-4.5,4.5V15.5c0,2.49,2.01,4.5,4.5,4.5h12c2.49,0,4.5-2.01,4.5-4.5V6.5c0-2.49-2.01-4.5-4.5-4.5Zm0,1c.95,0,1.82,.38,2.45,1l-5.97,5.97c-1.37,1.37-3.58,1.37-4.95,0L5.05,4c.63-.62,1.5-1,2.45-1h12Zm3.5,12.5c0,1.93-1.57,3.5-3.5,3.5H7.5c-1.93,0-3.5-1.57-3.5-3.5V6.5c0-.62,.16-1.19,.44-1.7l5.88,5.88c.88,.88,2.03,1.32,3.18,1.32s2.3-.44,3.18-1.32l5.88-5.88c.28,.5,.44,1.08,.44,1.7V15.5Zm-5,7c0,.28-.22,.5-.5,.5H4.5c-2.48,0-4.5-2.02-4.5-4.5V7.5c0-.28,.22-.5,.5-.5s.5,.22,.5,.5v11c0,1.93,1.57,3.5,3.5,3.5h13c.28,0,.5,.22,.5,.5Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'email'
};
</script>

<style lang="scss" scoped>
svg {
  fill: #704ad1;
  overflow: hidden;
  vertical-align: middle;
  transform: rotateZ(0deg);
  height: 25px;
  width: 25px;
}
</style>
